import classNames from 'classnames'
import { FeaturedProject } from '@/types/codegen-contentful'
import { useSafeRouter } from '@/utils/nextUtils/safe-router'
import { FeaturedProjectBackground } from './FeaturedProjectBackground'
import { FeaturedProjectCallToAction } from './FeaturedProjectCallToAction'
import { FeaturedProjectExperimentWrapper } from './FeaturedProjectExperimentWrapper'
import { assignBackgroundImage } from './FeaturedProjectUtils'

export interface FeaturedProjectProps {
  featuredProject: FeaturedProject
}

export const FeaturedProjectDisplay: React.FC<FeaturedProjectProps> = ({ featuredProject }) => {
  const {
    backgroundImageExperiment,
    ctaPosition,
    desktopBackgroundUrl,
    mobileBackgroundUrl,
    tabletBackgroundUrl,
    ultrawideBackgroundUrl,
  } = featuredProject

  const { asPath } = useSafeRouter()
  const tablet = tabletBackgroundUrl ?? desktopBackgroundUrl

  const backgrounds = {
    mobile: mobileBackgroundUrl as string,
    tablet: tablet as string,
    desktop: desktopBackgroundUrl as string,
    ultra: ultrawideBackgroundUrl as string,
    gradient: true,
    gravity: 'g_face',
  }

  const backgroundImage = assignBackgroundImage(backgrounds)
  const shorterDisplay = asPath.includes('/watch/dry-bar-best-of')

  return (
    <div
      className={classNames(
        'relative bg-transparent',
        shorterDisplay
          ? 'h-[70vh] lg:min-h-[400px] lg:h-[675px] lg:max-w-[1200px] lg:mx-auto lg:rounded-2xl lg:overflow-hidden'
          : 'h-[90vh] lg:min-h-[1000px] shortDesktop:min-h-[800px]',
      )}
    >
      {backgroundImageExperiment ? (
        <FeaturedProjectExperimentWrapper featuredProject={featuredProject} />
      ) : (
        <div className="relative h-full w-full">
          <FeaturedProjectBackground backgroundImage={backgroundImage} />
          <FeaturedProjectCallToAction
            featuredProject={featuredProject}
            position={ctaPosition ?? 'left'}
            shorterDisplay={shorterDisplay}
          />
        </div>
      )}
    </div>
  )
}
