import classNames from 'classnames'
import { When } from 'react-if'
import { Image } from '@/atoms/Image'
import { CaptionMD, ParagraphLG } from '@/atoms/Text'
import { useGuildMember } from '@/services/GuildUserService'
import { FeaturedProject } from '@/types/codegen-contentful'
import { getCloudinaryImageUrl } from '@/utils/Cloudinary'
import { FeaturedProjectCarousel } from './FeaturedProjectCarousel'
import { FeaturedProjectGuildButtonGroup } from './FeaturedProjectGuildButtonGroup'
import { FeaturedProjectGuildLabel } from './FeaturedProjectGuildLabel'
import { FeaturedProjectLabel } from './FeaturedProjectLabel'
import { FeaturedProjectTicketsButtonGroup } from './FeaturedProjectTicketsButtonGroup'
import { FeaturedProjectWatchButtonGroup } from './FeaturedProjectWatchButtonGroup'

interface FeaturedProjectCallToActionProps {
  featuredProject: FeaturedProject
  position?: string
  shorterDisplay?: boolean
}

export const FeaturedProjectCallToAction: React.FC<FeaturedProjectCallToActionProps> = ({
  featuredProject,
  position,
  shorterDisplay = false,
}) => {
  const { isSubscribedToPifOrGuild, isGuildMember } = useGuildMember()
  const {
    heading,
    guildHeading,
    logoHeight,
    logoUrl,
    logoWidth,
    pillLabel,
    showGuildCta,
    showTicketsCta,
    subHeading,
    showWatchCta,
  } = featuredProject

  const imageHeight = shorterDisplay ? (logoHeight ?? 0) / 3 : logoHeight ?? 0
  const imageWidth = shorterDisplay ? (logoWidth ?? 0) / 3 : logoWidth ?? 0

  return (
    <div
      className={classNames(
        'absolute bottom-0 flex w-full left-1/2 max-w-[800px] -translate-x-1/2 flex-col items-center justify-center px-6 pb-8 sm:pb-0',
        {
          'lg:bottom-0': position === 'center',
        },
        position === 'left' && {
          'lg:bottom-0 lg:left-24 lg:right-auto -translate-x-1/2 lg:translate-x-0 2xl:left-40': true,
          'lg:-translate-y-1/2 2xl:bottom-12': !shorterDisplay,
          'lg:-translate-y-1/3': shorterDisplay,
        },
        position === 'right' && {
          'lg:bottom-0 lg:left-auto lg:right-24 -translate-x-1/2 lg:-translate-x-0 2xl:right-40': true,
          'lg:-translate-y-1/2 2xl:bottom-12': !shorterDisplay,
          'lg:-translate-y-1/3': shorterDisplay,
        },
      )}
    >
      <div
        className={classNames(
          'flex w-full flex-col items-center gap-4 pb-12 text-center lg:w-fit lg:items-start lg:pb-8',
          {
            'sm:mb-10': !shorterDisplay,
          },
        )}
      >
        {showGuildCta && <FeaturedProjectGuildLabel />}
        <div className="flex w-full items-center justify-center">
          <Image
            alt="Featured Project"
            className="mb-1 !max-w-full"
            src={getCloudinaryImageUrl({
              path: logoUrl ?? '',
              transforms: 'e_trim',
            })}
            style={{ maxHeight: imageHeight, maxWidth: imageWidth }}
            width={imageWidth}
            height={imageHeight}
          />
        </div>
        <div className="w-full text-center">
          <When condition={isGuildMember}>
            {(heading || guildHeading) && <ParagraphLG color="white">{guildHeading ?? heading}</ParagraphLG>}
          </When>
          <When condition={!isGuildMember}>{heading && <ParagraphLG color="white">{heading}</ParagraphLG>}</When>
          {subHeading && <CaptionMD color="white">{subHeading}</CaptionMD>}
        </div>
        {pillLabel && <FeaturedProjectLabel featuredProject={featuredProject} />}
        {showGuildCta && (
          <FeaturedProjectGuildButtonGroup
            {...featuredProject}
            isSubscribedToPifOrGuild={isSubscribedToPifOrGuild}
            isGuildMember={isGuildMember}
          />
        )}
        {showTicketsCta && <FeaturedProjectTicketsButtonGroup {...featuredProject} />}
        {showWatchCta && <FeaturedProjectWatchButtonGroup {...featuredProject} />}
        <FeaturedProjectCarousel featuredProject={featuredProject} />
      </div>
    </div>
  )
}
